import React from "react";
import {
  AnchorMenus,
  HotelTitle,
  MobileAddressBar,
  MobileMenuContainer,
  StickyContainer,
  StyledContainer,
} from "../../HotelPageHelpers";
import { Col, Row, Spinner } from "react-bootstrap";
import { ISearchState } from "../../../../redux/slices/Search/search";
import {
  addressBar,
  addressBarContainer,
  phone,
} from "../../HotelPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { desktopOnly, mobileOnly } from "../../../../styles/global.module.scss";

export const AddressBar = (props: {
  directionLink: string;
  phone: string;
  hotelInfoFontColor: string;
  address: {
    address_line1?: string;
    locality?: string;
    administrative_area?: string;
    postal_code?: string;
  };
}): JSX.Element => {
  const _address = props.address;
  const _directionsLink = props.directionLink;
  const _phone = props.phone;

  return (
    <div className={addressBar}>
      <a
        href={_directionsLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: props.hotelInfoFontColor }}
        id="hotel-address"
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          color={props.hotelInfoFontColor}
        />
        <span
          className={desktopOnly}
          style={{ color: props.hotelInfoFontColor }}
        >
          {_address.address_line1}, {_address.locality},{" "}
          {_address.administrative_area} {_address.postal_code}
        </span>
        <span
          className={mobileOnly}
          style={{ color: props.hotelInfoFontColor }}
        >
          Get Directions
        </span>
      </a>
      {_phone !== null && (
        <a
          href={`tel:${_phone}`}
          className={`${phone}`}
          style={{ color: props.hotelInfoFontColor }}
          id="hotel-phone"
        >
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className="me-2"
            color={props.hotelInfoFontColor}
          />
          <span
            className={desktopOnly}
            style={{ color: props.hotelInfoFontColor }}
          >
            {_phone}
          </span>
          <span
            className={mobileOnly}
            style={{ color: props.hotelInfoFontColor }}
          >
            Call Hotel
          </span>
        </a>
      )}
    </div>
  );
};
interface AnchorLink {
  label: string;
  anchor: string;
}

interface AddressBarProps {
  hotelName: string;
  hotelInfoBrandBarBgColor: string;
  hotelInfoBrandBarFontColor: string;
  showAnchor: boolean;
  anchorLinks: AnchorLink[];
  tabId: string | null;
  handleClickTab: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  hotelInfoActiveColor: string;
}

export const HelperAddressBar: React.FC<AddressBarProps> = ({
  hotelName,
  hotelInfoBrandBarBgColor,
  hotelInfoBrandBarFontColor,
  showAnchor,
  anchorLinks,
  tabId,
  handleClickTab,
  hotelInfoActiveColor,
}) => (
  <AddressBarContainer
    hotelInfoBgColor={hotelInfoBrandBarBgColor}
    hotelInfoFontColor={hotelInfoBrandBarFontColor}
  >
    <StyledContainer fluid="sm">
      <Row className="align-items-center">
        <Col lg={5} className="col5 colBase1 hotelTitleColumn">
          <HotelTitle
            id="hotel-name"
            title={hotelName}
            hotelInfoFontColor={hotelInfoBrandBarFontColor}
          />
        </Col>
        <Col
          lg={7}
          className="col7 colBase1 hotelAnchorMenusCol"
          style={{ paddingLeft: 0 }}
        >
          {showAnchor ? (
            <AnchorMenus
              anchors={anchorLinks}
              tabId={tabId}
              onClick={handleClickTab}
              hotelInfoFontColor={hotelInfoBrandBarFontColor}
              hotelInfoActiveColor={hotelInfoActiveColor}
            />
          ) : (
            <div className="anchorSpinner">
              <Spinner animation="border" />
            </div>
          )}
        </Col>
      </Row>
    </StyledContainer>
  </AddressBarContainer>
);

interface Address {
  address_line1?: string;
  locality?: string;
  administrative_area?: string;
  postal_code?: string;
}

interface MobileAddressBarProps {
  logo: string;
  searchData: ISearchState;
  hotelName: string;
  address: Address;
  hotelInfoBrandBarBgColor: string;
  hotelInfoBrandBarFontColor: string;
  anchorLinks: AnchorLink[];
  tabId: string | null;
  handleClickTab: (_id: string) => void;
  hotelInfoActiveColor: string;
  directionsLink: string;
  hotelPhone: string;
}

export const HelperMobileAddressBar: React.FC<MobileAddressBarProps> = ({
  logo,
  searchData,
  hotelInfoBrandBarBgColor,
  hotelInfoBrandBarFontColor,
  hotelName,
  address,
  directionsLink,
  hotelPhone,
  anchorLinks,
  tabId,
  handleClickTab,
  hotelInfoActiveColor,
}) => (
  <StickyContainer logo={logo} searchData={searchData}>
    <MobileAddressBar
      hotelInfoBgColor={hotelInfoBrandBarBgColor}
      hotelInfoFontColor={hotelInfoBrandBarFontColor}
    >
      <Row>
        <Col className="col5 colBase1">
          <HotelTitle
            hotelInfoFontColor={hotelInfoBrandBarFontColor}
            title={hotelName}
          />
          <AddressBar
            hotelInfoFontColor={hotelInfoBrandBarFontColor}
            address={address}
            directionLink={directionsLink}
            phone={hotelPhone}
          />
        </Col>
      </Row>
    </MobileAddressBar>
    <MobileMenuContainer hotelInfoBgColor={hotelInfoBrandBarBgColor}>
      <AnchorMenus
        anchors={anchorLinks}
        tabId={tabId}
        onClick={handleClickTab}
        hotelInfoBgColor={hotelInfoBrandBarBgColor}
        hotelInfoActiveColor={hotelInfoActiveColor}
        hotelInfoFontColor={hotelInfoBrandBarFontColor}
      />
    </MobileMenuContainer>
  </StickyContainer>
);

export const AddressBarContainer = (props: {
  children: any;
  hotelInfoBgColor: string;
  hotelInfoFontColor: string;
}): JSX.Element => {
  return (
    <div
      className={addressBarContainer}
      style={{
        backgroundColor: props.hotelInfoBgColor,
        color: props.hotelInfoFontColor,
      }}
    >
      {props.children}
    </div>
  );
};
